define('ember-table/views/lazy-table-block', ['exports', 'ember', 'ember-table/mixins/register-table-component', 'ember-table/views/lazy-container'], function (exports, Ember, RegisterTableComponentMixin, LazyContainerView) {

  'use strict';

  exports['default'] = LazyContainerView['default'].extend(RegisterTableComponentMixin['default'], {
    classNames: ['ember-table-table-block'],
    styleBindings: ['width'],
    itemViewClass: Ember['default'].computed.alias('tableComponent.tableRowViewClass'),
    rowHeight: Ember['default'].computed.alias('tableComponent.rowHeight'),
    columns: null,
    content: null,
    scrollLeft: null,
    scrollTop: null,

    onScrollLeftDidChange: Ember['default'].observer(function () {
      this.$().scrollLeft(this.get('scrollLeft'));
    }, 'scrollLeft')
  });

});