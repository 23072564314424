define('ember-getowner-polyfill/index', ['exports', 'ember', 'ember-getowner-polyfill/fake-owner'], function (exports, Ember, FakeOwner) {

  'use strict';

  var hasGetOwner = !!Ember['default'].getOwner;

  exports['default'] = function (object) {
    var owner = undefined;

    if (hasGetOwner) {
      owner = Ember['default'].getOwner(object);
    }

    if (!owner && object.container) {
      owner = new FakeOwner['default'](object);
    }

    return owner;
  }

});