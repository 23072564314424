define('ember-table/views/column-sortable-indicator', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], RegisterTableComponentMixin['default'], {
    classNames: 'ember-table-column-sortable-indicator',
    classNameBindings: 'tableComponent._isShowingSortableIndicator:active',
    styleBindings: ['left', 'height'],
    left: Ember['default'].computed.alias('tableComponent._sortableIndicatorLeft'),
    height: Ember['default'].computed.alias('tableComponent._height')
  });

});