define('ember-table/views/header-row', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], RegisterTableComponentMixin['default'], {
    templateName: 'header-row',
    classNames: ['ember-table-table-row', 'ember-table-header-row'],
    styleBindings: ['width'],
    columns: Ember['default'].computed.alias('content'),
    width: Ember['default'].computed.alias('tableComponent._rowWidth'),

    // Options for jQuery UI sortable
    sortableOption: Ember['default'].computed(function () {
      return {
        axis: 'x',
        containment: 'parent',
        cursor: 'move',
        helper: 'clone',
        items: ".ember-table-header-cell.sortable",
        opacity: 0.9,
        placeholder: 'ui-state-highlight',
        scroll: true,
        tolerance: 'pointer',
        update: Ember['default'].$.proxy(this.onColumnSortDone, this),
        stop: Ember['default'].$.proxy(this.onColumnSortStop, this),
        sort: Ember['default'].$.proxy(this.onColumnSortChange, this)
      };
    }),

    didInsertElement: function didInsertElement() {
      this._super();
      if (this.get('tableComponent.enableColumnReorder')) {
        this.$('> div').sortable(this.get('sortableOption'));
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (this.get('tableComponent.enableColumnReorder')) {
        // TODO(azirbel): Get rid of this check, as in onColumnSortDone?
        var $divs = this.$('> div');
        if ($divs) {
          $divs.sortable('destroy');
        }
      }
      this._super();
    },

    onColumnSortStop: function onColumnSortStop() {
      this.set('tableComponent._isShowingSortableIndicator', false);
    },

    onColumnSortChange: function onColumnSortChange() {
      var left = this.$('.ui-state-highlight').offset().left - this.$().closest('.ember-table-tables-container').offset().left;
      this.set('tableComponent._isShowingSortableIndicator', true);
      this.set('tableComponent._sortableIndicatorLeft', left);
    },

    onColumnSortDone: function onColumnSortDone(event, ui) {
      var newIndex = ui.item.index();
      this.$('> div').sortable('cancel');
      var view = Ember['default'].View.views[ui.item.attr('id')];
      var column = view.get('column');
      this.get('tableComponent').onColumnSort(column, newIndex);
      this.set('tableComponent._isShowingSortableIndicator', false);
    }
  });

});