define('ember-table/views/header-table-container', ['exports', 'ember', 'ember-table/views/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/register-table-component'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = TableContainer['default'].extend(ShowHorizontalScrollMixin['default'], RegisterTableComponentMixin['default'], {
    templateName: 'header-table-container',
    classNames: ['ember-table-table-container', 'ember-table-fixed-table-container', 'ember-table-header-container'],
    height: Ember['default'].computed.alias('tableComponent._headerHeight'),
    width: Ember['default'].computed.alias('tableComponent._tableContainerWidth')
  });

});