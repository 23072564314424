define('ember-table/views/table-block', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].CollectionView.extend(StyleBindingsMixin['default'], RegisterTableComponentMixin['default'], {
    classNames: ['ember-table-table-block'],
    styleBindings: ['width', 'height'],
    itemViewClass: Ember['default'].computed.alias('tableComponent.tableRowViewClass'),
    columns: null,
    content: null,
    scrollLeft: null,

    onScrollLeftDidChange: Ember['default'].observer(function () {
      this.$().scrollLeft(this.get('scrollLeft'));
    }, 'scrollLeft'),

    height: Ember['default'].computed(function () {
      return this.get('tableComponent._headerHeight');
    }).property('tableComponent._headerHeight')
  });

});