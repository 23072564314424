define('ember-table/views/footer-table-container', ['exports', 'ember', 'ember-table/views/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/register-table-component', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin, RegisterTableComponentMixin, MouseWheelHandlerMixin, TouchMoveHandlerMixin) {

  'use strict';

  exports['default'] = TableContainer['default'].extend(MouseWheelHandlerMixin['default'], TouchMoveHandlerMixin['default'], ShowHorizontalScrollMixin['default'], RegisterTableComponentMixin['default'], {
    templateName: 'footer-table-container',
    classNames: ['ember-table-table-container', 'ember-table-fixed-table-container', 'ember-table-footer-container'],
    styleBindings: 'top',
    height: Ember['default'].computed.alias('tableComponent.footerHeight'),
    width: Ember['default'].computed.alias('tableComponent._tableContainerWidth'),
    scrollLeft: Ember['default'].computed.alias('tableComponent._tableScrollLeft'),

    top: Ember['default'].computed(function () {
      var headerHeight = this.get('tableComponent._headerHeight');
      var contentHeight = this.get('tableComponent._tableContentHeight') + headerHeight;
      var bodyHeight = this.get('tableComponent._bodyHeight') + headerHeight;
      if (contentHeight < bodyHeight) {
        return contentHeight;
      } else {
        return bodyHeight;
      }
    }).property('tableComponent._bodyHeight', 'tableComponent._headerHeight', 'tableComponent._tableContentHeight'),

    onMouseWheel: function onMouseWheel(event, delta, deltaX) {
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    onTouchMove: function onTouchMove(event, deltaX) {
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    }
  });

});