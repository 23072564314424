define('ember-table/controllers/row', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ObjectProxy.extend({
    content: null,

    isShowing: true,
    isHovered: false,

    isSelected: Ember['default'].computed(function (key, val) {
      if (arguments.length > 1) {
        this.get('parentController').setSelected(this, val);
      }
      return this.get('parentController').isSelected(this);
    }).property('parentController.selection.[]')
  });

});