define('ember-table/views/scroll-panel', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], RegisterTableComponentMixin['default'], {
    classNames: ['ember-table-scroll-panel'],
    styleBindings: ['width', 'height'],
    width: Ember['default'].computed.alias('tableComponent._tableColumnsWidth'),
    height: Ember['default'].computed.alias('tableComponent._tableContentHeight')
  });

});