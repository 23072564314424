define('ember-highcharts/components/high-charts', ['exports', 'ember', 'ember-highcharts/utils/option-loader', 'ember-getowner-polyfill'], function (exports, Ember, option_loader, getOwner) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var get = Ember['default'].get;
  var set = Ember['default'].set;
  var on = Ember['default'].on;
  var run = Ember['default'].run;
  var $ = Ember['default'].$;

  var assign = Ember['default'].assign || Ember['default'].merge;

  exports['default'] = Component.extend({
    classNames: ['highcharts-wrapper'],
    content: undefined,
    mode: undefined,
    chartOptions: undefined,
    chart: null,
    theme: undefined,
    callback: undefined,

    buildOptions: computed('chartOptions', 'content.[]', function () {
      var chartOptions = $.extend(true, {}, get(this, 'theme'), get(this, 'chartOptions'));
      var chartContent = get(this, 'content.length') ? get(this, 'content') : [{
        id: 'noData',
        data: 0,
        color: '#aaaaaa'
      }];

      var defaults = { series: chartContent };

      return assign(defaults, chartOptions);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('content', 'chart', 'mode');

      var content = _getProperties.content;
      var chart = _getProperties.chart;
      var mode = _getProperties.mode;

      if (!content || !chart) {
        return;
      }

      var isStockChart = mode === 'StockChart';

      // create maps to make series data easier to work with
      var contentSeriesMap = content.reduce(function (contentSeriesMap, contentSeries) {
        contentSeriesMap[contentSeries.name] = contentSeries;
        return contentSeriesMap;
      }, {});

      var chartSeriesMap = chart.series.reduce(function (chartSeriesMap, chartSeries) {
        chartSeriesMap[chartSeries.name] = chartSeries;
        return chartSeriesMap;
      }, {});

      // remove and update current series
      var chartSeriesToRemove = [];

      chart.series.forEach(function (series) {
        if (isStockChart && series.name === 'Navigator') {
          return;
        }

        var contentSeries = contentSeriesMap[series.name];

        if (!contentSeries) {
          return chartSeriesToRemove.push(series);
        }

        series.setData(contentSeries.data, false);
      });

      chartSeriesToRemove.forEach(function (series) {
        return series.remove(false);
      });

      // add new series
      content.forEach(function (contentSeries) {
        if (!chartSeriesMap.hasOwnProperty(contentSeries.name)) {
          chart.addSeries(contentSeries, false);
        }
      });

      // reset navigator data
      if (isStockChart && chart.xAxis.length) {
        chart.xAxis[0].setExtremes();
      }

      return chart.redraw();
    },

    drawAfterRender: function drawAfterRender() {
      run.scheduleOnce('afterRender', this, 'draw');
    },

    draw: function draw() {
      var completeChartOptions = [get(this, 'buildOptions'), get(this, 'callback')];
      var mode = get(this, 'mode');

      if (typeof mode === 'string' && !!mode) {
        completeChartOptions.unshift(mode);
      }

      var $element = this.$();
      if ($element) {
        var chart = $element.highcharts.apply($element, completeChartOptions).highcharts();
        set(this, 'chart', chart);
      }
    },

    _renderChart: on('didInsertElement', function () {
      this.drawAfterRender();
      option_loader.setDefaultHighChartOptions(getOwner['default'](this));
    }),

    _destroyChart: on('willDestroyElement', function () {
      if (get(this, 'chart')) {
        get(this, 'chart').destroy();
      }
    })
  });

});