define('ember-table/controllers/row-array', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].ArrayController.extend({
    itemController: null,
    content: null,
    lastItem: null,

    rowContent: Ember['default'].computed(function () {
      return [];
    }).property(),

    controllerAt: function controllerAt(idx, object) {
      var subControllers = this.get('_subControllers');
      var subController = subControllers[idx];
      if (subController) {
        return subController;
      }
      subController = this.get('itemController').create({
        target: this,
        parentController: this.get('parentController') || this,
        content: object
      });
      subControllers[idx] = subController;

      // Keep track of the last row. Because we only use last row status to apply
      // CSS styles, we only need to know which row is the last row when the row
      // is loaded/scrolled into view. At that time, we update the `lastItem`
      // property. This avoids the problem where the last row's data is always
      // loaded (e.g. in the AJAX table). See issue #165.
      if (this._isLastItem(idx)) {
        this.set('lastItem', subController);
      }
      return subController;
    },

    _isLastItem: function _isLastItem(idx) {
      return this.get('content').length - 1 === idx;
    }
  });

});