define('ember-table/views/multi-item-collection', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].CollectionView.extend(StyleBindingsMixin['default'], {
    styleBindings: 'width',
    itemViewClassField: null,

    createChildView: function createChildView(view, attrs) {
      var itemViewClassField = this.get('itemViewClassField');
      var itemViewClass = attrs.content.get(itemViewClassField);
      if (typeof itemViewClass === 'string') {
        if (/[A-Z]+/.exec(itemViewClass)) {
          // Global var lookup - 'App.MessagePreviewView'
          itemViewClass = Ember['default'].get(Ember['default'].lookup, itemViewClass);
        } else {
          // Ember CLI Style lookup - 'message/preview'
          itemViewClass = this.container.lookupFactory("view:" + itemViewClass);
        }
      }
      return this._super(itemViewClass, attrs);
    }
  });

});