define('ember-table/mixins/mouse-wheel-handler', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    onMouseWheel: Ember['default'].K,

    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super();
      this.$().bind('mousewheel', function (event, delta, deltaX, deltaY) {
        Ember['default'].run(_this, _this.onMouseWheel, event, delta, deltaX, deltaY);
      });
    },

    willDestroyElement: function willDestroyElement() {
      var $elem = this.$();
      if ($elem) {
        $elem.unbind('mousewheel');
      }
      this._super();
    }
  });

});