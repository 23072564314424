define('ember-table/views/body-table-container', ['exports', 'ember', 'ember-table/views/table-container', 'ember-table/mixins/show-horizontal-scroll', 'ember-table/mixins/register-table-component', 'ember-table/mixins/mouse-wheel-handler', 'ember-table/mixins/touch-move-handler', 'ember-table/mixins/scroll-handler'], function (exports, Ember, TableContainer, ShowHorizontalScrollMixin, RegisterTableComponentMixin, MouseWheelHandlerMixin, TouchMoveHandlerMixin, ScrollHandlerMixin) {

  'use strict';

  exports['default'] = TableContainer['default'].extend(MouseWheelHandlerMixin['default'], TouchMoveHandlerMixin['default'], ScrollHandlerMixin['default'], ShowHorizontalScrollMixin['default'], RegisterTableComponentMixin['default'], {
    templateName: 'body-table-container',
    classNames: ['ember-table-table-container', 'ember-table-body-container', 'antiscroll-wrap'],

    height: Ember['default'].computed.alias('tableComponent._bodyHeight'),
    width: Ember['default'].computed.alias('tableComponent._width'),
    scrollTop: Ember['default'].computed.alias('tableComponent._tableScrollTop'),
    scrollLeft: Ember['default'].computed.alias('tableComponent._tableScrollLeft'),
    scrollElementSelector: '.antiscroll-inner',

    onScroll: function onScroll(event) {
      this.set('scrollTop', event.target.scrollTop);
      return event.preventDefault();
    },

    // `event` here is a jQuery event
    onMouseWheel: function onMouseWheel(event, delta, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    },

    // `event` here is a jQuery event
    onTouchMove: function onTouchMove(event, deltaX, deltaY) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        return;
      }
      var scrollLeft = this.$('.ember-table-right-table-block').scrollLeft() + deltaX;
      this.set('scrollLeft', scrollLeft);
      event.preventDefault();
    }
  });

});