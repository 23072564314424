/**
 * Created by udaras on 5/18/2015.
 */

(function ($) {

    var autoCompleteConst = {
        SELECTING_TYPE: {
            SELECTED_ONLY: 1,
            LEAVES_ONLY: 2,
            BOTH: 3,
            WITH_PARENTS: 4
        }
    };

    var defaultSeparator = '~';
    var methods = {

        init: function (options) {

            var defaults = {
                data: [],
                width: 495,
                height: 250,
                btnWidth:253,
                multiSelect: false,
                placeHolder: 'Search',
                caseSensitive: false,
                searchableLevels: undefined,
                selectableLevels: undefined,
                selectingType: autoCompleteConst.SELECTING_TYPE.SELECTED_ONLY,
                expandedTree: false,
                alignment: "left",
                uniqueKeys : false,
                selectAllChildren : true,
                showToggleButtons : true,
                customTextWidth : undefined
            };

            var settings = $.extend({}, defaults, options);
            _populateAutoCompleteBox(settings, this);

            if(typeof settings.data !== "undefined" && settings.data.length > 0){
                _populateAutoCompleteTree(settings, this);
                _bindSearch(settings, this);
                _bindEvents(settings, this);
                _initSelectedTree(settings, this);
            }
            methods.bindEvents.apply(this, []);
            return $(this);
        },
        bindEvents: function () {
            var container = $(this);
            var containerId = container.attr('id');
            var selector = container.find('div[ref="' + containerId + '_container"]');

            container.find('button[ref="' + containerId + '_button"]').bind('click', function () {
                if (selector.is(':visible')) {
                    selector.hide();
                } else {
                    selector.show();
                    container.find('input[ref="' + containerId + '_input"]').focus();
                }

                $('div[name="autocomplete_search"]').not(selector).each(function () {
                    $(this).hide();
                });
            });

            $(document).click(function (e) {
                if (selector.is(':visible')) {
                    selector.hide();
                }
            });

            container.find('input[ref="' + containerId + '_done_btn"]').bind('click', function () {
                if (selector.is(':visible')) {
                    selector.hide();
                }
            });

            container.click(function (event) {
                event.stopPropagation(this);
            });

            return $(this);
        },
        getData: function (level, isReduced, seperator) {
            var results = [];
            var container = $(this);
            var containerId = $(container).attr('id');
            var keys = container.find('div[ref="' + containerId + '_selected_box"]').attr('selectedKeys').split(',');

            if(level !== undefined && isReduced === undefined) {
                $.each(keys, function(k,key){
                    if(key.trim() != '') {
                        var params = key.split(defaultSeparator);
                        if (params[0] === level.toString()) {
                            results.push(params[1]);
                        }
                    }
                });
            } else if(level === undefined && isReduced === true && seperator !== undefined){
                $.each(keys, function(k,key){
                    if(key.trim() !== '') {
                        results.push(key);
                    }
                });
                results = _reduceResults(results, seperator);

            } else if (seperator !== undefined){
                results = _removeEmptyInArray(keys, seperator);
            } else {
                results = keys;
            }

            return results;
        },
        getParentCode: function () {
            var container = $(this);
            var containerId = $(container).attr('id');
            var selectedCode = container.find('div[ref="' + containerId + '_selected_box"]').attr('selectedCodes');
            if (selectedCode != undefined && selectedCode != '') {
                return selectedCode.split("_")[1];
            } else {
                return '';
            }
        },
        getUniqueData : function (level) {
            var results = [];
            var container = $(this);
            var containerId = $(container).attr('id');
            var uniqueKeys = container.find('div[ref="' + containerId + '_selected_box"]').attr('selectedUniqueKeys').split(',');

            if(level !== undefined){
                $.each(uniqueKeys, function(k, key){
                    if(key.trim() != '') {
                        var params = key.split(defaultSeparator);
                        if (params[0] === level.toString()) {
                            results.push(params[1]);
                        }
                    }
                });
            } else {
                results = uniqueKeys;
            }
            return results;
        },
        reset: function () {
            var container = $(this);
            var containerId = $(container).attr('id');
            var selectedCountSpan = container.find('span[ref="' + containerId + '_selected_count"]');
            var closeAll = container.find('a[ref="' + containerId + '_col_btn"]');
            var deselectAll = container.find('a[ref="' + containerId + '_all_de_sel_btn"]');
            var selectedTreeContainer = container.find('div[ref="' + containerId + '_selected_box"]');
            deselectAll.trigger('click');
            closeAll.trigger('click');
            _clearSingleSelection(selectedCountSpan, selectedTreeContainer);
        }
    };


    var _reduceResults = function (data, separator) {
        var length = data.length;
        var reducedData = [];
        var k, j;

        for (k = 0; k < length; k++) {
            for (j = 0; j < length; j++) {
                if (j != k && data[j] != '' && data[j].split(defaultSeparator).length > 1 && data[k].split(defaultSeparator).length > 1) {
                    if (data[j].split(defaultSeparator)[1].lastIndexOf(data[k].split(defaultSeparator)[1],0)===0) {
                        data[j] = '';
                    }
                }
            }
        }

        return _removeEmptyInArray(data, separator);
    };

    var _bindEvents = function (settings, container) {
        var containerId = $(container).attr('id');
        var closeAll = container.find('button[ref="' + containerId + '_col_btn"]');
        var expandAll = container.find('button[ref="' + containerId + '_exp_btn"]');
        var selectAll = container.find('button[ref="' + containerId + '_sel_all_btn"]');
        var deselectAll = container.find('button[ref="' + containerId + '_all_de_sel_btn"]');
        var selectedTreeContainer = container.find('div[ref="' + containerId + '_selected_box"]');
        var clearSingleSelection = container.find('span[ref="' + containerId + '_single_clear_btn"]');
        var selectedCountSpan = container.find('span[ref="' + containerId + '_selected_count"]');

        expandAll.on("click", function (event) {
            event.preventDefault();
            settings.expandedTree = true;
            _populateAutoCompleteTree(settings, container);
        });

        closeAll.on("click", function (event) {
            event.preventDefault();
            settings.expandedTree = false;
            _populateAutoCompleteTree(settings, container);
        });

        if(settings.multiSelect === true) {
            selectAll.on("click", function (event) {
                event.preventDefault();
                var arr = _getAllKeysInTree(settings.data, 'r', settings);

                arr[0] = _removeEmptyInArray(arr[0].split(','), defaultSeparator);
                arr[1] = _removeEmptyInArray(arr[1].split(','), defaultSeparator);

                _populateSelectedTree(settings, container, arr[1], arr[0]);

                var uniqueArrays = _getUniqueSelectedArrays(arr[0], arr[1]);
                selectedTreeContainer.attr('selectedCodes', arr[1].join(','));
                selectedTreeContainer.attr('selectedKeys', arr[0].join(','));
                selectedTreeContainer.attr('selectedUniqueKeys', uniqueArrays.keys.join(','));
                selectedTreeContainer.attr('selectedUniqueCodes', uniqueArrays.codes.join(','));

                _setSelectedCount(container, containerId, uniqueArrays.keys.length);
            });

            deselectAll.on("click", function (event) {
                event.preventDefault();
                selectedTreeContainer.html('');
                _setSelectedCount(container, containerId, 0);
                selectedTreeContainer.attr('selectedCodes', '');
                selectedTreeContainer.attr('selectedKeys', '');
                selectedTreeContainer.attr('selectedUniqueKeys', '');
                selectedTreeContainer.attr('selectedUniqueCodes', '');
            });
        } else {
            clearSingleSelection.on("click", function (event) {
                event.preventDefault();
                event.stopPropagation();
                _clearSingleSelection(selectedCountSpan, selectedTreeContainer);
                var selector = container.find('div[ref="' + containerId + '_container"]');
                if (selector.is(':visible')) {
                    selector.hide();
                }
            });
        }
    };

    var _clearSingleSelection = function(selectedCountSpan, selectedTreeContainer) {
        selectedTreeContainer.html('');
        selectedCountSpan.html('Select Options');
        selectedTreeContainer.attr('selectedCodes', '');
        selectedTreeContainer.attr('selectedKeys', '');
        selectedTreeContainer.trigger('change');
    };

    var _getAllKeysInTree = function (data, parentKey, settings) {
        var keys = [], codes = [], string =['',''];
        $.each(data, function (i, lData) {
            if (typeof lData.type === "undefined" || lData.type !== "E") { // if not leaves
                var arr = _getAllKeysInTree(lData.children, parentKey + '_' + lData.key, settings);
                string[0] += arr [0];
                string[1] += arr [1];
            }

            if (_isToBind(settings, lData.level)) {
                keys.push(lData.level + defaultSeparator + lData.key);
                codes.push(parentKey + '_' + lData.key);
            }
        });

        return [string[0] +','+keys.join(','), string[1] +','+codes.join(',')];
    };

    var _bindSearch = function (settings, container) {
        var containerId = $(container).attr('id');
        var input = container.find('input[ref="' + containerId + '_input"]');
        input.keyup(function () {
            var v = $(input).val();
            setTimeout(function () {
                _populateAutoCompleteTree(settings, container, v.trim() === '' ? undefined : v);
            }, 250);
        });
    };

    var _populateAutoCompleteTree = function (settings, container, searchKey) {
        var data = settings.data;
        var containerId = $(container).attr('id');
        var sourceTreeContainer = container.find('div[ref="' + containerId + '_source_box"]');
        var selectedTreeContainer = container.find('div[ref="' + containerId + '_selected_box"]');
        var html = (searchKey !== undefined) ? _getAutoCompleteLevelSearchHtml(data, 'r', searchKey, settings.caseSensitive, settings.searchableLevels, settings).html :
            _getAutoCompleteLevelHtml(data, 'r', settings.expandedTree, settings);
        sourceTreeContainer.html(html);

        sourceTreeContainer.find('[ref="arrow"]').bind('click', function () {
            $($(this).parents()[1]).next().slideToggle();
            $(this).toggleClass("a_collapse a_expand");
        });

        sourceTreeContainer.find('li [ref="title"]').bind('click', function () {
            var li = $(this).parent();
            var level = li.attr('level');
            var codeData = [[],[]];
            ((searchKey !== undefined && settings.selectAllChildren === true) ? _getAllSelectingCodes(li.attr('code'), 'r', settings.data, codeData[0], codeData[1], false) : codeData =  _getSelectingCodes(settings, li));
            var codes = codeData[0];
            var keys = codeData[1];

            if (_isToBind(settings, level)) {
                var selectedCodesArr = (settings.multiSelect === true ? selectedTreeContainer.attr('selectedCodes').split(',') : []);
                var selectedKeysArr = (settings.multiSelect === true ? selectedTreeContainer.attr('selectedKeys').split(',') : []);
                $.each(codes, function (k, code) {
                    if (selectedCodesArr.indexOf(code) < 0) {
                        selectedCodesArr.push(code);
                    }
                });

                $.each(keys, function (k, key) {
                    //if (selectedKeysArr.indexOf(key) < 0) {
                    if(selectedCodesArr.length != selectedKeysArr.length) {
                        selectedKeysArr.push(key);
                    }

                    //}
                });
                selectedTreeContainer.attr('selectedCodes', selectedCodesArr.join(','));
                selectedTreeContainer.attr('selectedKeys', selectedKeysArr.join(','));
                if(settings.multiSelect === true) {
                    var uniqueArrays = _getUniqueSelectedArrays(selectedKeysArr, selectedCodesArr);
                    selectedTreeContainer.attr('selectedUniqueCodes', uniqueArrays.codes.join(','));
                    selectedTreeContainer.attr('selectedUniqueKeys', uniqueArrays.keys.join(','));
                    _setSelectedCount(container, containerId, uniqueArrays.keys.length);
                    _populateSelectedTree(settings, container, selectedCodesArr, selectedKeysArr);
                } else {
                    _setSingleSelected(container, containerId, _getFirstNonEmptyDesc(settings.data, (selectedKeysArr[0]).split(defaultSeparator)[1], undefined), settings);
                    var selector = container.find('div[ref="' + containerId + '_container"]');
                    if (selector.is(':visible')) {
                        selector.hide();
                    }
                }
                selectedTreeContainer.trigger('change');
            }
        });
    };

    var _setSingleSelected = function(container, containerId, selected, settings) {
        var selectedCountSpan = container.find('span[ref="' + containerId + '_selected_count"]');
        selectedCountSpan.html(selected !== undefined ? _formatTitle(selected, settings, undefined) : 'Select Options');
    };

    var _getFirstNonEmptyDesc = function (data, selected, description) {
        if(description === undefined){
            $.each(data, function (i, lData) {
                var tempDes, currentKey;
                if (typeof lData.type !== "undefined" && lData.type === "E") { // if leaves
                    if (selected == lData.key) {
                        description = lData.title;
                    }
                } else {
                    if (selected == lData.key) {
                        description = lData.title;
                    } else if(description === undefined) {
                        tempDes = _getFirstNonEmptyDesc(lData.children, selected, description);
                        description =  (tempDes ? tempDes : description);
                    }
                }
            });
        }
        return description;
    };

    var _initSelectedTree = function(settings, container) {
        var parentKey = 'r';
        var selectedCodesArr = [];
        var selectedKeysArr = [];
        var data = settings.data;
        var containerId = $(container).attr('id');
        var selectedTreeContainer = container.find('div[ref="' + containerId + '_selected_box"]');

        _populateSelectingArrays(data, parentKey, selectedCodesArr, selectedKeysArr);
        var uniqueArrays = _getUniqueSelectedArrays(selectedKeysArr,selectedCodesArr);

        if(settings.uniqueKeys === true) {
            selectedCodesArr = uniqueArrays.codes;
            selectedKeysArr = uniqueArrays.keys;
        }

        selectedTreeContainer.attr('selectedCodes', selectedCodesArr.join(','));
        selectedTreeContainer.attr('selectedKeys', selectedKeysArr.join(','));
        selectedTreeContainer.attr('selectedUniqueCodes', uniqueArrays.codes.join(','));
        selectedTreeContainer.attr('selectedUniqueKeys', uniqueArrays.keys.join(','));

        if(settings.multiSelect === true) {
            _setSelectedCount(container, containerId, uniqueArrays.keys.length);
            _populateSelectedTree(settings, container, selectedCodesArr, selectedKeysArr);
        } else {
            if (selectedKeysArr.length > 0) {
                _setSingleSelected(container, containerId, _getFirstNonEmptyDesc(settings.data, (selectedKeysArr[0]).split(defaultSeparator)[1], undefined), settings);
            }
        }
    };

    var _getUniqueSelectedArrays = function (keys, codes) {
        var temp1 = [];
        var temp2 = [];
        var uniqueKeys = [];
        var uniqueCodes = [];
        for (var i = 0; i < keys.length; i++) {
            if (keys[i] !== '' && uniqueKeys.indexOf(keys[i]) < 0) {
                uniqueKeys.push(keys[i]);
            }
        }
        for (var j = 0; j < codes.length; j++) {
            if (codes[j] !== '' && uniqueCodes.indexOf(codes[j]) < 0) {
                uniqueCodes.push(codes[j]);
            }
        }

        for (i = 0; i < uniqueCodes.length - 1; i++) {
            for (j = i + 1; j < uniqueCodes.length; j++) {
                temp1 = uniqueCodes[i].split('_');
                temp2 = uniqueCodes[j].split('_');

                if (temp1.length == temp2.length && temp1.length > 1) {
                    if (temp1[temp1.length-1] === temp2[temp2.length-1]) {
                        uniqueCodes[j] = '';
                    }
                }
            }
        }

        uniqueCodes = _removeEmptyInArray(uniqueCodes);

        return {keys: uniqueKeys, codes: uniqueCodes};
    };

    var _populateSelectingArrays = function (data, parentcode, selectedCodesArr, selectedKeysArr) {
        var code;
        $.each(data, function (k, lData) {
            code = parentcode + '_' +lData.key;
            if(typeof lData.type !== "undefined" && lData.type === "E") {
                if(typeof lData.select !== "undefined" && lData.select == true) {
                    selectedCodesArr.push(code);
                    selectedKeysArr.push(lData.level+defaultSeparator+lData.key);
                }
            }
            else {
                if(typeof lData.select !== "undefined" && lData.select == true) {
                    selectedCodesArr.push(code);
                    selectedKeysArr.push(lData.level+defaultSeparator+lData.key);
                }
                _populateSelectingArrays(lData.children, code, selectedCodesArr, selectedKeysArr);
            }
        });
    };

    var _setSelectedCount = function(container, containerId, count){
        var selectedCountSpan = container.find('span[ref="' + containerId + '_selected_count"]');
        //count --; // since this has empty one
        selectedCountSpan.html(count > 0 ? count + ' Selected' : 'Select Options');
    };

    var _getAllSelectingCodes = function(selectedCode, parentCode, data, codes, keys, found) {
        var code;
        $.each(data, function (k, lData) {
            code = parentCode + '_' +lData.key;

            if(lData.type === undefined || lData.type !== "E") {
                if(selectedCode == code) {
                    found = true;
                    codes.push(code);
                    keys.push(lData.level+defaultSeparator+lData.key);
                    _selectAllChildren(code, lData.children, codes, keys);
                } else if (!found) {
                    _getAllSelectingCodes(selectedCode, code, lData.children, codes, keys, found);
                }
            } else {
                if(selectedCode == code) {
                    found = true;
                    codes.push(code);
                    keys.push(lData.level+defaultSeparator+lData.key);
                }
            }
        });
    };

    var _selectAllChildren = function (parentCode, data, codes, keys) {
        var code;
        $.each(data, function (k, lData) {
            code = parentCode + '_' +lData.key;
            codes.push(code);
            keys.push(lData.level+defaultSeparator+lData.key);
            if(typeof lData.type === "undefined" || lData.type !== "E") {
                _selectAllChildren(code, lData.children, codes, keys);
            }
        });
    };

    var _getSelectingCodes = function (settings, li) {
        var codes = [], keys = [], ele;
        switch (settings.selectingType) {
            case autoCompleteConst.SELECTING_TYPE.SELECTED_ONLY:
                codes.push(li.attr('code'));
                keys.push(li.attr('level') +defaultSeparator + li.attr('key'));
                break;
            case autoCompleteConst.SELECTING_TYPE.LEAVES_ONLY:
                if(li.attr('type') === 'E') {
                    codes.push(li.attr('code'));
                    keys.push(li.attr('level') +defaultSeparator + li.attr('key'));
                }

                ele = li.parent().next();
                if(ele.is('div')) {
                    $.each(ele.find('li'), function () {
                        var ele2 = $(this);
                        if(ele2.attr('type') === 'E') {
                            codes.push(ele2.attr('code'));
                            keys.push(ele2.attr('level') + defaultSeparator + ele2.attr('key'));
                        }
                    });
                }
                break;
            case autoCompleteConst.SELECTING_TYPE.BOTH:
                codes.push(li.attr('code'));
                keys.push(li.attr('level') +defaultSeparator + li.attr('key'));
                ele = li.parent().next();
                if(ele.is('div')) {
                    $.each(ele.find('li'), function () {
                        var ele2 = $(this);
                        codes.push(ele2.attr('code'));
                        keys.push(ele2.attr('level') +defaultSeparator + ele2.attr('key'));
                    });
                }
                break;
            case autoCompleteConst.SELECTING_TYPE.WITH_PARENTS:
                codes.push(li.attr('code'));
                keys.push(li.attr('level') +defaultSeparator + li.attr('key'));
                break;
            default :
                codes.push(li.attr('code'));
                keys.push(li.attr('level') +defaultSeparator + li.attr('key'));
        }
        return [codes,keys];
    };

    var _isToBind = function (settings, level) {
        if (settings.selectableLevels === undefined) {
            return true;
        }
        return settings.selectableLevels.indexOf(parseInt(level)) >= 0;
    };

    var _populateSelectedTree = function (settings, container, selectedCodesArr, selectedKeysArr) {
        var data = settings.data;
        var containerId = $(container).attr('id');
        var selectedTreeContainer = container.find('div[ref="' + containerId + '_selected_box"]');
        var sourceTreeContainer = container.find('div[ref="' + containerId + '_source_box"]');
        var html = _getSelectedTreeHtml(data, 'r', selectedCodesArr, settings);
        selectedTreeContainer.html(html);

        selectedTreeContainer.find('.btn_close').bind('click', function () {
            var li = $(this).parent();
            var code = li.attr('code');
            var key = li.attr('level') +defaultSeparator+ li.attr('key');
            selectedCodesArr.splice(selectedCodesArr.indexOf(code), 1);
            selectedKeysArr.splice(selectedKeysArr.indexOf(key), 1);
            selectedTreeContainer.attr('selectedCodes', selectedCodesArr.join(','));
            selectedTreeContainer.attr('selectedKeys', selectedKeysArr.join(','));

            var uniqueArrays = _getUniqueSelectedArrays(selectedKeysArr,selectedCodesArr);

            selectedTreeContainer.attr('selectedUniqueCodes', uniqueArrays.codes.join(','));
            selectedTreeContainer.attr('selectedUniqueKeys', uniqueArrays.keys.join(','));
            _setSelectedCount(container, containerId, uniqueArrays.codes.length);
//            sourceTreeContainer.find('[code="' + code + '"] [ref="title"]').removeAttr('disabled');
            $(this).parents()[1].remove();

            selectedTreeContainer.trigger('change');
        });
    };

    var _getSelectedTreeHtml = function (data, parentKey, selectedCodesArr, settings) {
        var code, html='';

        $.each(data, function (i, lData) {
            code = parentKey + '_' + lData.key;
            if (typeof lData.type !== "undefined" && lData.type === "E") { // if leaves
                if (selectedCodesArr.indexOf(code) >= 0) {
                    html += '<ul class="L_' + lData.level + '"><li level="' + lData.level + '" code="' + parentKey + '_' +
                        lData.key + '" key="' + lData.key + '" type="E"><span ref="title" title="' + lData.tooltip + '">' + _formatTitle(lData.title, settings, lData.level) +
                        '</span><span class="btn_close" ></span></li></ul>';
                }
            } else {
                if (selectedCodesArr.indexOf(code) >= 0) {
                    html += '<ul class="L_' + lData.level + '"><li level="' + lData.level + '" code="' + parentKey + '_' +
                        lData.key + '" key="' + lData.key + '"><span ref="title" title="' + lData.tooltip + '">' + _formatTitle(lData.title, settings, lData.level) +
                        '</span><span class="btn_close"></span></li></ul>';
                }
                html += _getSelectedTreeHtml(lData.children, parentKey + '_' + lData.key, selectedCodesArr, settings);
            }
        });
        return html;
    };

    var _getAutoCompleteLevelHtml = function (data, parentKey, expandedTree, settings) {
        var html = '<div' + ((parentKey === 'r' || expandedTree) ? '' : ' style="display: none;"') + '>';

        $.each(data, function (i, lData) {
            if (typeof lData.type !== "undefined" && lData.type === "E") { // if leaves
                html += '<ul class="L_' + lData.level + '" ><li level="' + lData.level + '" code="' + parentKey + '_' +
                    lData.key + '" key="' + lData.key + '" type="E"><span class = "a_title" ref="title" title="' + lData.tooltip + '">' + _formatTitle(lData.title, settings, lData.level) + '</span></li></ul>';

            } else {
                html += '<ul class="L_' + lData.level + '" ><li level="' + lData.level + '" code="' + parentKey + '_' +
                    lData.key + '" key="' + lData.key + '"><span class=' + (expandedTree ? "a_expand" : "a_collapse") +
                    ' ref="arrow" ></span><span class = "a_title" ref="title" title="' + lData.tooltip + '">' + _formatTitle(lData.title, settings, lData.level) + '</span></li></ul>';

                html += _getAutoCompleteLevelHtml(lData.children, parentKey + '_' + lData.key, expandedTree, settings);
            }
        });
        html += '</div>';
        return html;
    };

    var _removeEmptyInArray = function (data, separator) {
        var array = [];
        var length = data.length;
        var i;
        if (data != null && typeof data != 'undefined' ) {

            if(separator === undefined){
                for (i = 0; i < length; i++) {
                    if(data[i] != null && data[i] != ''){
                        array.push(data[i]);
                    }
                }
            }
            else if (separator !== defaultSeparator) {
                for (i = 0; i < length; i++) {
                    if(data[i] != null && data[i] != ''){
                        array.push(data[i].split(defaultSeparator)[0] + separator + data[i].split(defaultSeparator)[1]);
                    }
                }
            }
            else {
                for (i = 0; i < length; i++) {
                    if(data[i] != null && data[i] != ''){
                        array.push(data[i]);
                    }
                }
            }
        }
        return array;
    };

    var _formatTitle = function formatTitle(title, settings, level){
        var formattedTitle = '';
        var lengthVal = (settings.customTextWidth ? settings.customTextWidth : settings.width);
        var defaultTitleLength = lengthVal/16.3 - 2 * (level ? level : 0);
        if(title && title != '') {
            if(title.length > defaultTitleLength) {
                formattedTitle = title.substring(0, defaultTitleLength);
            } else {
                formattedTitle = title;
            }
        }
        return formattedTitle == '' || formattedTitle == title ? formattedTitle: formattedTitle + '...';
    };

    var _getAutoCompleteLevelSearchHtml = function (data, parentKey, searchKey, caseSensitive, searchableLevels, settings) {
        var childHtml,html, selectBranch = false;
        html = '<div>';

        $.each(data, function (i, lData) {
            var search = false, title = '';
            if (typeof lData.type !== "undefined" && lData.type === "E") { // if leaves
                if (_isSearchableLevel(searchableLevels, lData.level) && _isSearched(lData.title, searchKey, caseSensitive)) {
                    title = _formatTitle(lData.title, settings, lData.level);
                    html += '<ul class="L_' + lData.level + '" ><li level="' + lData.level + '" code="' + parentKey + '_' +
                        lData.key + '" key="' + lData.key + '" type="E"><span ref="title" class = "a_title" title="' + lData.tooltip + '">' + _replaceTitleWithSearchKey(title, searchKey) + '</span></li></ul>';
                    search = true;
                    if(selectBranch !== true){
                        selectBranch = search;
                    }
                }
            } else {
                if (_isSearchableLevel(searchableLevels, lData.level) && _isSearched(lData.title, searchKey, caseSensitive, searchableLevels, lData.level)) {
                    search = true;
                }
                title = _formatTitle(lData.title, settings, lData.level);

                childHtml = _getAutoCompleteLevelSearchHtml(lData.children, parentKey + '_' + lData.key, searchKey, caseSensitive, searchableLevels, settings);
                search = childHtml.search || search;
                if(selectBranch !== true){
                    selectBranch = search;
                }
                if (search) {
                    html += '<ul class="L_' + lData.level + '" ><li level="' + lData.level + '" code="' + parentKey + '_' +
                        lData.key + '" key="' + lData.key + '"><span class=' + (childHtml.search ? "a_expand" : "" ) +
                        ' ref="arrow"></span><span ref="title" class = "a_title" title="' + lData.tooltip + '">' + _replaceTitleWithSearchKey(title, searchKey) + '</span></li></ul>';
                }

                if (childHtml.search) {
                    html += childHtml.html;
                }
            }
        });
        html += '</div>';

        return {'html': html, 'search': selectBranch};
    };

    var _isSearchableLevel = function (searchableLevels, level) {
        if (searchableLevels === undefined) {
            return true;
        } else {
            return searchableLevels.indexOf(level) >= 0;
        }
    };

    var _isSearched = function (title, searchKey, caseSensitive) {
        if (title === undefined || typeof title === "undefined" || typeof searchKey === "undefined") {
            return false;
        } else if (caseSensitive) {
            return title.indexOf(searchKey) >= 0;
        }
        return title.toString().toLowerCase().indexOf(searchKey.toString().toLowerCase()) >= 0;
    };

    var _replaceTitleWithSearchKey = function (title, searchKey) {
        return title.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(searchKey.trim()) + ")(?![^<>]*>)(?![^&;]+;)", "gi"), "<span class='autocomp-highlight'>$1</span>");
    };

    var _populateAutoCompleteBox = function (settings, div) {
        var height = settings.height;
        var divId = $(div).attr('id');
        var html="";
        html += "<div class=\"row\">";
        html += "            <div class=\"col-xs-12\">";
        html += "";
        html += "                <div class=\"panel-group no-radius\">";
        html += "                    <div class=\"widget-wrapper-style1 no-padding\">";
        html += "                        <div id="+divId+"_collapse  class=\"panel-collapse collapse\">";
        html += "                            <div class=\"panel-body\">";
        html += "                                <div class=\"row\">";
        html += "                                    <div class=\"col-xs-12\">";
        html += "                                        <input type=\"text\" ref="+divId+"_input class=\"input-text-field margin-medium-bottom\" placeholder=\"Search\"\/>";
        html += "                                    <\/div>";
        html += "                                <\/div>";
        html += "";
        html += "                                <div class=\"row margin-medium-bottom\">";
        html += "                                    <div class=\"col-xs-4\">";
        html += "                                        <button ref="+divId+"_exp_btn class=\"filter-btn-style-1 border-none center-block padding-small-top padding-large-right padding-small-bottom padding-large-left\">";
        html += "                                            <span>Expand All<\/span>";
        html += "                                        <\/button>";
        html += "                                    <\/div>";
        html += "                                    <div class=\"col-xs-4\">";
        html += "                                        <div class=\"row\">";
        html += "                                            <button ref="+divId+"_col_btn class=\"filter-btn-style-1 border-none center-block padding-small-top padding-large-right padding-small-bottom padding-large-left\">";
        html += "                                                <span>Collapse All<\/span>";
        html += "                                            <\/button>";
        html += "                                        <\/div>";
        html += "                                    <\/div>";
        html += "                                    <div class=\"col-xs-4\">";
        html += "                                        <button ref="+divId+"_sel_all_btn class=\"filter-btn-style-1 border-none center-block padding-small-top padding-large-right padding-small-bottom padding-large-left\">";
        html += "                                            <span>Select All<\/span>";
        html += "                                        <\/button>";
        html += "                                    <\/div>";
        html += "                                <\/div>";
        html += "";
        html += "                                <div class=\"row margin-large-bottom\">";
        html += "                                    <div class=\"col-xs-12\">";
        html += "                                        <div ref="+divId+"_source_box class=\"widget-wrapper-style1 full-width\">";
        html += "                                        <\/div>";
        html += "                                    <\/div>";
        html += "                                <\/div>";
        html += "";
        html += "                                <div class=\"horizontal-sep-line margin-small-top margin-medium-bottom\"><\/div>";
        html += "";
        html += "                                <div class=\"row\">";
        html += "                                    <div class=\"col-xs-12 margin-medium-bottom\">";
        html += "                                        <div class=\"left bold margin-small-top\">Selected<\/div>";
        html += "                                        <div class=\"right\">";
        html += "                                            <button ref="+divId+"_all_de_sel_btn class=\"filter-btn-style-1 border-none center-block padding-small-top padding-large-right padding-small-bottom padding-large-left\">";
        html += "                                                <span>Deselect All<\/span>";
        html += "                                            <\/button>";
        html += "                                        <\/div>";
        html += "                                    <\/div>";
        html += "                                    <div class=\"col-xs-12\">";
        html += "                                        <div ref="+divId+"_selected_box class=\"widget-wrapper-style1 full-width\">";
        html += "                                        <\/div>";
        html += "                                    <\/div>";
        html += "                                <\/div>";
        html += "";
        html += "                            <\/div>";
        html += "                        <\/div>";
        html += "                    <\/div>";
        html += "                <\/div>";
        html += "";
        html += "            <\/div>";
        html += "        <\/div>";

        $(div).html(html);
    };

    $.fn.setAutocomplete = function (methodOrOptions) {
        if (methodOrOptions == "bindEvents") {
            return $(this);
        } else if (methods[methodOrOptions]) {
            return methods[ methodOrOptions ].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object') {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + methodOrOptions + ' does not exist on jQuery.dfn.search.autocomplete');
        }
    };
})(jQuery);

