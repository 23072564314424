define('ember-table/views/scroll-container', ['exports', 'ember', 'ember-table/mixins/style-bindings', 'ember-table/mixins/register-table-component', 'ember-table/mixins/scroll-handler'], function (exports, Ember, StyleBindingsMixin, RegisterTableComponentMixin, ScrollHandlerMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], ScrollHandlerMixin['default'], RegisterTableComponentMixin['default'], {
    templateName: 'scroll-container',
    classNames: ['ember-table-scroll-container'],
    styleBindings: ['left', 'width', 'height'],
    scrollElementSelector: '.antiscroll-inner',
    width: Ember['default'].computed.alias('tableComponent._scrollContainerWidth'),
    // 10 is the height of the horizontal scrollbar
    height: 10,
    left: Ember['default'].computed.alias('tableComponent._fixedColumnsWidth'),
    scrollTop: Ember['default'].computed.alias('tableComponent._tableScrollTop'),
    scrollLeft: Ember['default'].computed.alias('tableComponent._tableScrollLeft'),

    // HACK: onScrollLeftDidChange will not fire unless scrollLeft has been get
    // at least once. Therefore, we want to call onScrollLeftDidChange in
    // didInsertElement
    didInsertElement: function didInsertElement() {
      this._super();
      this.onScrollLeftDidChange();
    },

    // `event` here is a jQuery event
    onScroll: function onScroll(event) {
      this.set('scrollLeft', event.target.scrollLeft);
      event.preventDefault();
    },

    onScrollLeftDidChange: Ember['default'].observer(function () {
      var selector = this.get('scrollElementSelector');
      this.$(selector).scrollLeft(this.get('scrollLeft'));
    }, 'scrollLeft', 'scrollElementSelector')
  });

});