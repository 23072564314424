define('ember-table/views/header-block', ['exports', 'ember', 'ember-table/views/table-block'], function (exports, Ember, TableBlock) {

  'use strict';

  exports['default'] = TableBlock['default'].extend({
    classNames: ['ember-table-header-block'],
    // TODO(new-api): Eliminate view alias
    itemView: 'header-row',
    itemViewClass: Ember['default'].computed.alias('itemView'),

    content: Ember['default'].computed(function () {
      return [this.get('columns')];
    }).property('columns'),

    onColumnsDidChange: Ember['default'].observer(function () {
      var _this = this;
      Ember['default'].run.schedule('afterRender', function () {
        if ((_this.get('_state') || _this.get('state')) !== 'inDOM') {
          return;
        }
        _this.$().scrollLeft(_this.get('scrollLeft'));
      });
    }, 'content')
  });

});