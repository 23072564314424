define('ember-table/views/lazy-item', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], {
    itemIndex: null,
    prepareContent: Ember['default'].K,
    teardownContent: Ember['default'].K,
    rowHeightBinding: 'parentView.rowHeight',
    styleBindings: ['width', 'top', 'display'],

    top: Ember['default'].computed(function () {
      return this.get('itemIndex') * this.get('rowHeight');
    }).property('itemIndex', 'rowHeight'),

    // TODO(azirbel): Add explicit else case
    display: Ember['default'].computed(function () {
      if (!this.get('content')) {
        return 'none';
      }
    }).property('content')
  });

});