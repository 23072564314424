define('ember-table/views/table-cell', ['exports', 'ember', 'ember-table/mixins/style-bindings'], function (exports, Ember, StyleBindingsMixin) {

  'use strict';

  exports['default'] = Ember['default'].View.extend(StyleBindingsMixin['default'], {
    // ---------------------------------------------------------------------------
    // API - Inputs
    // ---------------------------------------------------------------------------

    // TODO: Doc
    templateName: 'table-cell',
    classNames: ['ember-table-cell'],
    classNameBindings: 'column.textAlign',
    styleBindings: 'width',

    // ---------------------------------------------------------------------------
    // Internal properties
    // ---------------------------------------------------------------------------

    init: function init() {
      this._super();
      this.contentPathDidChange();
      this.contentDidChange();
    },

    row: Ember['default'].computed.alias('parentView.row'),
    column: Ember['default'].computed.alias('content'),
    width: Ember['default'].computed.alias('column.width'),

    contentDidChange: function contentDidChange() {
      this.notifyPropertyChange('cellContent');
    },

    contentPathWillChange: Ember['default'].beforeObserver(function () {
      var contentPath = this.get('column.contentPath');
      if (contentPath) {
        this.removeObserver("row." + contentPath, this, this.contentDidChange);
      }
    }, 'column.contentPath'),

    contentPathDidChange: Ember['default'].beforeObserver(function () {
      var contentPath = this.get('column.contentPath');
      if (contentPath) {
        this.addObserver("row." + contentPath, this, this.contentDidChange);
      }
    }, 'column.contentPath'),

    cellContent: Ember['default'].computed(function (key, value) {
      var row = this.get('row');
      var column = this.get('column');
      if (!row || !column) {
        return;
      }
      if (arguments.length === 1) {
        value = column.getCellContent(row);
      } else {
        column.setCellContent(row, value);
      }
      return value;
    }).property('row.isLoaded', 'column')
  });

});