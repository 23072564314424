define('ember-table/views/table-row', ['exports', 'ember', 'ember-table/mixins/register-table-component', 'ember-table/views/lazy-item'], function (exports, Ember, RegisterTableComponentMixin, LazyItemView) {

  'use strict';

  exports['default'] = LazyItemView['default'].extend(RegisterTableComponentMixin['default'], {
    templateName: 'table-row',
    classNames: 'ember-table-table-row',
    classNameBindings: ['row.isHovered:ember-table-hover', 'row.isSelected:ember-table-selected', 'row.rowStyle', 'isLastRow:ember-table-last-row'],
    styleBindings: ['width', 'height'],
    row: Ember['default'].computed.alias('content'),
    columns: Ember['default'].computed.alias('parentView.columns'),
    width: Ember['default'].computed.alias('tableComponent._rowWidth'),
    height: Ember['default'].computed.alias('tableComponent.rowHeight'),

    // Use `lastItem` (set manually) instead of the array's built-in `lastObject`
    // to avoid creating a controller for last row on table initialization.  If
    // this TableRow is the last row, then the row controller should have been
    // created and set to `lastItem` in RowArrayController, otherwise `lastItem`
    // is null.
    isLastRow: Ember['default'].computed(function () {
      return this.get('row') === this.get('tableComponent.bodyContent.lastItem');
    }).property('tableComponent.bodyContent.lastItem', 'row'),

    // TODO(azirbel): Could simplify slightly via
    // this.set('row.isHovered', true) and remove the temp variable.
    // Also applies below/elsewhere.
    mouseEnter: function mouseEnter() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', true);
      }
    },

    mouseLeave: function mouseLeave() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', false);
      }
    },

    teardownContent: function teardownContent() {
      var row = this.get('row');
      if (row) {
        row.set('isHovered', false);
      }
    }
  });

});